import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"

const StyledSliderCustom = styled(Slider)`
  .slick-prev {
    left: -30px !important;
  }

  .slick-prev, .slick-next {
    width: 40px !important;
    height: 40px !important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 40px !important;
    color: #dc2626 !important;
  }

  @media (max-width:767px) {
    .slick-prev {
      left: -10px !important;
    }

    .slick-next {
      right: -10px !important;
    }

    .slick-prev, .slick-next {
      width: 20px !important;
      height: 20px !important;
    }

    .slick-prev:before, .slick-next:before {
      font-size: 20px !important;
      color: #dc2626 !important;
    }
  }
`

const TestimonialsSection = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  return (
    <div className="bg-white w-full pt-16 pb-24 border-t border-b">
      <div className="w-11/12 max-w-screen-xl mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Why IT Proactive?</h2>

        <StyledSliderCustom  {...sliderSettings}>
          <div>
            <div className='p-6'>
              <div className='flex items-center justify-start mb-4'>
                <div>
                  <StaticImage
                    src='../../images/quote-icon-blue.png'
                    width={50}
                    layout="constrained"
                    alt="quote icon"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>

              <div className='border-b mb-6'>
                <p className='text-lg md:text-xl text-gray-800 mb-6'>The IT Proactive team is the BEST!!! They ALWAYS find solutions to every issue. 100% satisfied!!! They also make great recommendations for computer equipment/peripherals andI have ALWAYS been satisfied with their recommendations!! THANK YOU IT Proactive.</p>
              </div>       

              <div className='flex items-center justify-start space-x-4'>
                <div>
                  <StaticImage
                    src='../../images/clients/client-1.jpg'
                    width={80}
                    height={80}
                    layout="constrained"
                    alt="IT Proactive client"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                    className='rounded-full shadow'
                  />
                </div>

                <div>
                  <p className='text-gray-800 text-lg font-semibold'>Client Name</p>
                  <p className='text-gray-800 text-md italic font-medium'>Company Name</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='p-6'>
              <div className='flex items-center justify-start mb-4'>
                <div>
                  <StaticImage
                    src='../../images/quote-icon-blue.png'
                    width={50}
                    layout="constrained"
                    alt="quote icon"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>

              <div className='border-b mb-6'>          
                <p className='text-lg md:text-xl text-gray-800 mb-4'>Excellent team, fast and efficient, IT experts and with immediate response to any question. Thanks to Tyler, Aaron, David, Mickey and all the team for their awesome job.</p>
              </div>

              <div className='flex items-center justify-start space-x-4'>
                <div>
                  <StaticImage
                    src='../../images/clients/client-2.jpg'
                    width={80}
                    height={80}
                    layout="constrained"
                    alt="IT Proactive client"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                    className='rounded-full shadow'
                  />
                </div>

                <div>
                  <p className='text-gray-800 text-lg font-semibold'>Client Name</p>
                  <p className='text-gray-800 text-md italic font-medium'>Company Name</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='p-6'>
              <div className='flex items-center justify-start mb-4'>
                <div>
                  <StaticImage
                    src='../../images/quote-icon-blue.png'
                    width={50}
                    layout="constrained"
                    alt="quote icon"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>

              <div className='border-b mb-6'>          
                <p className='text-lg md:text-xl text-gray-800 mb-6'>David has been our go to guy for years now. Never let us down in times of crisis. Very timely response to any of our questions and a pleasure to work with. Can't go wrong with him.</p>
              </div>

              <div className='flex items-center justify-start space-x-4'>
                <div>
                  <StaticImage
                    src='../../images/clients/client-3.jpg'
                    width={80}
                    height={80}
                    layout="constrained"
                    alt="IT Proactive client"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                    className='rounded-full shadow'
                  />
                </div>

                <div>
                  <p className='text-gray-800 text-lg font-semibold'>Client Name</p>
                  <p className='text-gray-800 text-md italic font-medium'>Company Name</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='p-6'>
              <div className='flex items-center justify-start mb-4'>
                <div>
                  <StaticImage
                    src='../../images/quote-icon-blue.png'
                    width={50}
                    layout="constrained"
                    alt="quote icon"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>

              <div className='border-b mb-6'>          
                <p className='text-lg md:text-xl text-gray-800 mb-4'>More amazing testimonials from very satisfied clients here. More, more, more!</p>
              </div>

              <div className='flex items-center justify-start space-x-4'>
                <div>
                  <StaticImage
                    src='../../images/clients/client-2.jpg'
                    width={80}
                    height={80}
                    layout="constrained"
                    alt="IT Proactive client"
                    placeholder='tracedSVG'
                    formats={["auto", "webp", "avif"]}
                    className='rounded-full shadow'
                  />
                </div>

                <div>
                  <p className='text-gray-800 text-lg font-semibold'>Client Name</p>
                  <p className='text-gray-800 text-md italic font-medium'>Company Name</p>
                </div>
              </div>
            </div>
          </div>
        </StyledSliderCustom>
      </div>
    </div>
  )
}

export default TestimonialsSection